import React from "react";
import banner from "../images/img-banner.png";

export default function Home() {
  return (
    <>
      <div class="container has-text-centered">
        <h1 class="title is-size-1 has-text-centered has-text-white">
          <span class="has-text-weight-light">BedsideIO</span>
        </h1>
        <h2 class="subtitle has-text-centered">
          Never lose sleep over proxies again.
        </h2>
        <button class="button is-success is-rounded mb-0">Sold Out</button>
        <div class="columns is-centered mt-0">
          <div class="column is-half pt-0 pr-5 pl-5">
            <img src={banner} alt="banner" />
          </div>
        </div>
      </div>
    </>
  );
}
