import React from "react";
import axios from "axios";
import { Redirect, useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

export default function SetupCheckout() {
  const history = useHistory();
  let { password } = useParams();
  const { register, handleSubmit } = useForm();
  const isStage1 = localStorage.getItem("stage-1");

  function checkPassword() {
    axios
      .get(process.env.REACT_APP_API_URL + `/checkout/${password}`)
      .then((response) => {
        if (response.data.error) {
          history.push("/");
        }
        localStorage.setItem("stage-2", true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  if (JSON.parse(isStage1) === false) {
    return <Redirect to="/" />;
  } else {
    checkPassword();
  }

  const onSubmit = (data) => {
    const { email, id } = data;
    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/saveDetails",
      data: { email: email, id: id },
    })
      .then((res) => {
        if (res.data.error == false) {
          history.push(`/authorize/${password}`);
        }
      })
      .catch((error) => {
        console.log(error);
        history.goBack();
      });
  };

  return (
    <>
      <div class="container has-text-centered">
        <h1 class="title is-size-1 has-text-centered has-text-white">
          <span class="has-text-weight-light">BedsideIO</span>
        </h1>
        <h2 class="subtitle has-text-centered">Never lose sleep over proxies again.</h2>
        <div class="columns mt-6 is-centered">
          <div class="column is-two-fifths">
            <div class="card p-5">
              <h2 class="title has-text-centered">Purchase Details</h2>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div class="field">
                  <p class="control has-icons-left has-icons-right">
                    <input
                      class="input"
                      type="email"
                      name="email"
                      ref={register({ required: true })}
                      placeholder="Email"
                    />
                    <span class="icon is-small is-left">
                      <i class="fas fa-envelope"></i>
                    </span>
                  </p>
                </div>
                <div class="field">
                  <p class="control has-icons-left">
                    <input
                      class="input"
                      type="text"
                      name="id"
                      ref={register({ required: true })}
                      placeholder="Discord Name"
                    />
                    <span class="icon is-small is-left">
                      <i class="fab fa-discord"></i>
                    </span>
                  </p>
                </div>
                <div class="field">
                  <p class="control">
                    <input
                      type="submit"
                      class="button is-success is-fullwidth"
                      value="Buy Now"
                    />
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
