import React from "react";
import banner from "../images/img-banner.png";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import axios from "axios";

export default function Checkout() {
  const history = useHistory();
  let { password } = useParams();
  function checkPassword() {
    axios
      .get(process.env.REACT_APP_API_URL + `/checkout/${password}`)
      .then((response) => {
        if (response.data.error) {
          history.push("/");
          localStorage.setItem("stage-1", false);
        } else {
          localStorage.setItem("stage-1", true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  checkPassword();

  function handleClick() {
    history.push(`/details/${password}`);
  }
  return (
    <>
      <div class="container has-text-centered">
        <h1 class="title is-size-1 has-text-centered has-text-white">
          <span class="has-text-weight-light">BedsideIO</span>
        </h1>
        <h2 class="subtitle has-text-centered">
          Never lose sleep over proxies again.
        </h2>
        <button onClick={handleClick} class="button is-success is-rounded">
          Buy Now
        </button>
        <div class="columns is-centered mt-0">
          <div class="column pt-0 pr-5 pl-5 is-half">
            <img src={banner} alt="banner" />
          </div>
        </div>
      </div>
    </>
  );
}
