import React from "react";

export default function UserNotFound() {
  return (
    <>
      <div class="container has-text-centered">
        <h1 class="title is-size-1 has-text-centered has-text-white">
          <span class="has-text-weight-light">BedsideIO</span>
        </h1>
        <h2 class="subtitle has-text-centered">Never lose sleep over proxies again.</h2>
        <div class="columns is-centered mt-5">
          <h1 className="title is-size-1 has-text-white">
            404 page not found!
          </h1>
        </div>
      </div>
    </>
  );
}
