import React from "react";
import jsonwebtoken from "jsonwebtoken";
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import "bulma";
import Table from "./Table";
import { useHistory } from "react-router-dom";
import DetailsTable from "./DetailsTable";

export default function Admin() {
  let token = localStorage.getItem("token");
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const [passwords, getPasswords] = useState("");
  const [details, getDetails] = useState("");

  const onSubmit = (data) => {
    const { password, amount } = data;
    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/password/add",
      headers: {
        Authorization: token,
      },
      data: { password: password, amount: amount },
    })
      .then((res) => {
        if (res.data.error == false) {
          getAllPasswords();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllPasswords();
    getAllDetails();
  }, []);

  const getAllPasswords = () => {
    axios.get(process.env.REACT_APP_API_URL + "/password").then((response) => {
      const verifiedPasswords = jsonwebtoken.verify(
        response.data.token,
        process.env.REACT_APP_TOKEN_SECRET
      );
      getPasswords(verifiedPasswords.data);
    });
  };

  const getAllDetails = () => {
    axios.get(process.env.REACT_APP_API_URL + "/details").then((response) => {
      const verifiedDetails = jsonwebtoken.verify(
        response.data.token,
        process.env.REACT_APP_TOKEN_SECRET
      );
      getDetails(verifiedDetails.data);
      console.log(verifiedDetails);
    });
  };

  if (!token) {
    history.push("/login");
  } else if (token) {
    const verifiedToken = jsonwebtoken.verify(
      token,
      process.env.REACT_APP_TOKEN_SECRET
    );
    if (verifiedToken.email === process.env.REACT_APP_ADMIN_EMAIL) {
      <Redirect to="/admin" />;
    } else {
      history.push("/login");
    }
  }

  const logout = () => {
    localStorage.removeItem("token");
    history.push("/login");
  };

  return (
    <>
      <div class="columns is-centered">
        <div class="column is-two-fifths">
          <div class="card p-5">
            <h2 class="title has-text-centered">Add New Password</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div class="field">
                <p class="control has-icons-left has-icons-right">
                  <input
                    class="input"
                    type="text"
                    name="password"
                    ref={register({ required: true })}
                    placeholder="Password"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-lock"></i>
                  </span>
                </p>
              </div>
              <div class="field">
                <p class="control has-icons-left has-icons-right">
                  <input
                    class="input"
                    type="text"
                    name="amount"
                    ref={register({ required: true })}
                    placeholder="Amount"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-pound-sign"></i>
                  </span>
                </p>
              </div>
              <div class="field">
                <p class="control">
                  <input
                    type="submit"
                    value="Add Password"
                    class="button is-success is-fullwidth"
                  />
                </p>
              </div>
            </form>
            <button
              class="mt-3 button is-danger is-outlined is-fullwidth"
              onClick={logout}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
      <div className="columns m-5">
        <div class="column">
          <h2 class="title has-text-centered has-text-white">
            Saved Passwords
          </h2>
          <table class="table is-fullwidth is-bordered">
            <thead>
              <tr>
                <th>ID</th>
                <th>Password</th>
                <th>Amount</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <Table passwords={passwords} token={token} />
            </tbody>
          </table>
        </div>
        <div class="column">
          <h2 class="title has-text-centered has-text-white">
            Purchase Details
          </h2>
          <div className="table-container">
            <table class="table is-fullwidth is-bordered">
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Email</th>
                  <th>Discord ID</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <DetailsTable details={details} token={token} />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
