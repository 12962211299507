import React from "react";
import "bulma";
import logo from "../images/bedside-logo-white.png";

export default function Header() {
  return (
    <>
      <nav
        class="navbar is-spaced"
        role="navigation"
        aria-label="main navigation"
      >
        <div class="navbar-brand">
          <a class="navbar-item" href="/">
            <img src={logo} width="64" />
          </a>
        </div>
      </nav>
    </>
  );
}
