import React from "react";
import "bulma";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useHistory } from "react-router-dom";

export default function Login() {
  const history = useHistory();

  const { register, handleSubmit } = useForm();
  let token = localStorage.getItem("token");
  if (token) {
    history.push("/admin");
  }
  const onSubmit = (data) => {
    const { email, password } = data;
    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/login",
      data: { email: email, password: password },
    })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        history.push("/admin");
      })
      .catch((error) => {
        console.log(error);
        history.push("/login");
      });
  };

  return (
    <>
      <div class="columns mt-6 is-centered">
        <div class="column is-two-fifths">
          <div class="card p-5">
            <h2 class="title has-text-centered">Admin Login</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div class="field">
                <p class="control has-icons-left has-icons-right">
                  <input
                    class="input"
                    type="email"
                    name="email"
                    ref={register({ required: true })}
                    placeholder="Email"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-envelope"></i>
                  </span>
                </p>
              </div>
              <div class="field">
                <p class="control has-icons-left">
                  <input
                    class="input"
                    type="password"
                    name="password"
                    ref={register({ required: true })}
                    placeholder="Password"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-lock"></i>
                  </span>
                </p>
              </div>
              <div class="field">
                <p class="control">
                  <input type="submit" class="button is-success is-fullwidth" />
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
