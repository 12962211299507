import dotenv from "dotenv";
import SetupCheckout from "./components/SetupCheckout";
import Header from "./components/Header";
import Login from "./components/Login";
import Home from "./components/Home";
import Admin from "./components/Admin";
import Checkout from "./components/Checkout";
import UserNotFound from "./components/UserNotFound";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./css/style.css";
import StripeCheckout from "./components/StripeCheckout";
dotenv.config();

function App() {
  return (
    <body>
      <Router>
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/admin" component={Admin} />
          <Route exact path="/checkout/:password" component={Checkout} />
          <Route exact path="/details/:password" component={SetupCheckout} />
          <Route path="/authorize/:password" component={StripeCheckout} />
          <Route component={UserNotFound} />
        </Switch>
      </Router>
    </body>
  );
}

export default App;
