import React from "react";
import axios from "axios";

export default function Table(props) {
  const { token } = props;

  const onSubmit = (data) => {
    axios({
      method: "get",
      url: process.env.REACT_APP_API_URL + `/password/delete/${data}`,
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        if (res.data.error == false) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const displayPasswords = (props) => {
    const { passwords } = props;
    if (passwords.length > 0) {
      return passwords.map((data, index) => {
        return (
          <tr>
            <td>{index + 1}</td>
            <td>{data.password}</td>
            <td>&pound;{data.amount}</td>
            <td>
              <a
                onClick={() => {
                  onSubmit(data.id);
                }}
              >
                <i style={{ color: "black" }} class="fas fa-trash-alt"></i>
              </a>
            </td>
          </tr>
        );
      });
    } else {
      return <div></div>;
    }
  };
  return <>{displayPasswords(props)}</>;
}
