import React from "react";
import axios from "axios";

export default function DetailsTable(props) {
  const { token } = props;

  const onSubmit = (data) => {
    axios({
      method: "get",
      url: process.env.REACT_APP_API_URL + `/details/delete/${data}`,
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        if (res.data.error == false) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const displayDetails = (props) => {
    const { details } = props;
    if (details.length > 0) {
      return details.map((data) => {
        return (
          <tr>
            <td>{data.time}</td>
            <td>{data.email}</td>
            <td>{data.discord}</td>
            <td>
              <a
                onClick={() => {
                  onSubmit(data.id);
                }}
              >
                <i style={{ color: "black" }} class="fas fa-trash-alt"></i>
              </a>
            </td>
          </tr>
        );
      });
    } else {
      return <div></div>;
    }
  };
  return <>{displayDetails(props)}</>;
}
